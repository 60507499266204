<template>
  <div>
    <div class="side to_top">
      <a rel="" href="javascript:;" class="back-to-top" @click="GoTop">
        <b-img-lazy :src="`${require('../assets/images/icon_top.png')}`" alt="Top" />
        <span>Top</span>
      </a>
    </div>
    <div class="side wechat d-md-block d-none">
      <b-img-lazy :src="`${require('../assets/images/icon_wechat.png')}`" :alt="$t('關注微訊信號')" />
      <div class="icon_qr">
        <b-img-lazy :src="`${require('../assets/images/icon_qr.jpg')}`" :alt="$t('關注微訊信號')" />
        <p>{{ $t('關注微訊信號') }}</p>
      </div>
    </div>
    <div class="side facebook d-md-block d-none">
      <a rel="noopener noreferrer" href="//www.facebook.com/taifungbankmo" target="_blank">
        <b-img-lazy :src="`${require('../assets/images/top_icon_facebook.png')}`" alt="facebook" />
      </a>
    </div>
    <div class="banner" v-show="swiperInit">
      <div class="banner_pc" v-if="homepage && homepage.banners">
        <!-- <b-carousel
          id="carousel-1"
          ref="myCarousel"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
        >
          <div v-for="banner in homepage.banners" :key="banner.id">
            <b-link rel="" :href="banner.url" :target="banner.newWindow ? '_blank' : ''" v-if="banner.url">
              <b-carousel-slide :img-src="banner.file"> </b-carousel-slide>
            </b-link>
            <b-carousel-slide :img-src="banner.file" v-else> </b-carousel-slide>
          </div>
        </b-carousel> -->
        <div class="position-relative">
          <div class="carousel-control-prev banner-swiper-button-prev">
            <span class="carousel-control-prev-icon" title="left"></span>
          </div>
          <div class="carousel-control-next banner-swiper-button-next">
            <span class="carousel-control-next-icon" title="right"></span>
          </div>

          <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="isMounted">
            <swiper-slide v-for="banner in homepage.banners" :key="banner.id">
              <b-link rel="noopener noreferrer" :href="banner.url" :target="banner.newWindow ? '_blank' : ''">
                <b-img :src="banner.file"></b-img>
              </b-link>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination" title="pagination"></div>
        </div>
      </div>
      <div class="banner_mb" v-if="homepage && homepage.bannersMobile">
        <!-- <b-carousel
    id="carousel-1"
    ref="myCarousel"
    :interval="4000"
    background="#ababab"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333"
  >
    <b-link
      v-for="banner_mobile in homepage.bannersMobile"
      :key="banner_mobile.id"
      :href="banner_mobile.url"
      :target="banner_mobile.newWindow ? '_blank' : ''"
    >
      <b-carousel-slide :img-src="banner_mobile.file"> </b-carousel-slide>
    </b-link>
  </b-carousel> -->
        <swiper ref="bannerMobileSwiper" :options="bannerSwiperOptions" v-if="isMounted">
          <swiper-slide v-for="banner_mobile in homepage.bannersMobile" :key="banner_mobile.id">
            <b-link
              rel="noopener noreferrer"
              :href="banner_mobile.url"
              :target="banner_mobile.newWindow ? '_blank' : ''"
            >
              <b-img :src="banner_mobile.file"></b-img>
            </b-link>
          </swiper-slide>
        </swiper>
      </div>
      <div class="navigation custom-container">
        <div class="navigation_pc" v-if="homepage && homepage.navigation">
          <b-row>
            <b-col
              class="d-flex navigation_item"
              v-for="navigation_item in homepage.navigation"
              :key="navigation_item.id"
            >
              <a
                :href="navigation_item.url"
                :target="navigation_item.newWindow ? '_blank' : ''"
                class="d-inline-block col-12"
              >
                <div class="banner_nav_item">
                  <b-img :src="navigation_item.file" :alt="navigation_item.title" />
                  <div class="title">
                    <div v-html="navigation_item.title"></div>
                  </div>
                </div>
              </a>
              <div class="between">
                <b-img
                  :src="`${require('../assets/images/icon0.png')}`"
                  class="separate"
                  :alt="navigation_item.title"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="navigation_mb d-none" v-if="homepage && homepage.navigation">
          <a
            v-for="navigation_item in homepage.navigation"
            :key="navigation_item.id"
            :href="navigation_item.url"
            :target="navigation_item.newWindow ? '_blank' : ''"
          >
            <div class="banner_nav_item">
              <b-img :src="navigation_item.file" :alt="navigation_item.title" />
              <div class="title">
                <div v-html="navigation_item.title"></div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!--專題活動-->
    <template v-if="homepage && homepage.calendarActivity && isMounted">
      <u-animate-container v-if="homepage.calendarActivity.length > 0 && home_activity == 1">
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="0.5s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
        >
          <div class="message">
            <div class="text-center border-0 pb-3 custom-container">
              <div class="homepage_title message_title">
                <p>
                  <span>{{ $t('專題活動') }}</span>
                </p>
              </div>
              <div class="message_content">
                <ul class="d-flex flex-wrap mx-2 mx-md-0">
                  <li
                    :class="[
                      homepage.calendarActivity.length < 2 ? 'col-md-12' : ' col-md-6',
                      'col-12 col-sm-12 p-1 p-md-3'
                    ]"
                    v-for="news_item in homepage.calendarActivity"
                    :key="news_item.id"
                  >
                    <b-link
                      :href="news_item.url ? news_item.url : ''"
                      :to="news_item.url ? '' : { name: 'activity-detail', params: { id: news_item.id } }"
                      class="d-flex flex-wrap pb-3"
                    >
                      <div class="col-12 col-sm-12 col-md-12 p-0">
                        <b-img-lazy
                          :src="news_item.cover ? news_item.cover : require('../assets/images/s_banner0.png')"
                          :alt="news_item.title"
                          style="max-height:200px;    object-fit: cover;"
                        />
                      </div>
                    </b-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </u-animate>
      </u-animate-container>
    </template>
    <!--最新消息-->
    <template v-if="homepage && homepage.news">
      <u-animate-container v-if="isMounted">
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="0.5s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
        >
          <div class="message">
            <div class="text-center border-0 pb-3 custom-container">
              <div class="homepage_title message_title">
                <p>
                  <span>{{ $t('最新消息') }}</span>
                </p>
              </div>
              <div class="message_content">
                <ul class="d-flex flex-wrap mx-2 mx-md-0">
                  <li class="col-6 col-sm-6 col-md-4 p-1 p-md-3" v-for="news_item in homepage.news" :key="news_item.id">
                    <b-link
                      :href="news_item.url ? news_item.url : ''"
                      :to="news_item.url ? '' : { name: 'news-detail', params: { id: news_item.id } }"
                      :target="news_item.newWindow ? '_blank' : ''"
                      class="d-flex flex-wrap pb-3 content_item"
                    >
                      <div class="col-12 col-sm-12 col-md-5 p-0">
                        <b-img-lazy
                          :src="news_item.cover ? news_item.cover : require('../assets/images/s_banner0.png')"
                          :alt="news_item.title"
                        />
                      </div>
                      <div class="col-12 col-sm-12 col-md-7 my-auto item_text">
                        <p class="blue mt-1 ">
                          {{ news_item ? news_item.inputAt.split(' ')[0] : '' }}
                        </p>
                        <p class="pr-2">{{ news_item.title }}</p>
                      </div>
                    </b-link>
                  </li>
                </ul>
                <ul>
                  <div class="more my-4">
                    <b-link rel="" :to="{ name: 'news' }" class="more_buttom">
                      <b-icon icon="caret-right-fill" :title="$t('更多')"></b-icon>{{ $t('更多') }}
                    </b-link>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </u-animate>
      </u-animate-container>
    </template>
    <!--業務及優惠信息-->
    <template v-if="homepage && homepage.promotions">
      <u-animate-container v-if="isMounted">
        <u-animate
          name="fadeInUp"
          delay="0s"
          duration="0.5s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
        >
          <div class="business">
            <div class="text-center border-0 pb-3 custom-container">
              <div class="homepage_title business_title">
                <p>
                  <span>{{ $t('業務及優惠信息') }}</span>
                </p>
              </div>
              <div class="business_content text-left">
                <ul class="d-flex flex-wrap">
                  <li
                    v-for="promotion_item in homepage.promotions"
                    class="col-12 col-sm-12 col-md-6"
                    :key="promotion_item.id"
                  >
                    <b-link
                      :href="promotion_item.url ? promotion_item.url : ''"
                      :to="promotion_item.url ? '' : { name: 'promotion-detail', params: { id: promotion_item.id } }"
                      :target="promotion_item.newWindow ? '_blank' : ''"
                      class="content_item d-flex align-items-center"
                    >
                      <b-icon icon="chevron-right" :title="promotion_item.title"></b-icon>{{ promotion_item.title }}
                    </b-link>
                  </li>
                  <li class="col-12 p-4 more">
                    <b-link rel="" :to="{ name: 'promotion' }" class="more_buttom">
                      <b-icon icon="caret-right-fill" :title="$t('更多')"></b-icon>{{ $t('更多') }}
                    </b-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </u-animate>
      </u-animate-container>
    </template>
    <!--便捷服務-->
    <template v-if="homepage && homepage.convenientService && isMounted">
      <u-animate-container v-if="isMounted">
        <u-animate delay="0s" duration="0.5s" :iteration="1" :offset="0" animateClass="fadeInUp" :begin="false">
          <div class="services">
            <div class="text-center border-0 pb-5 custom-container">
              <div class="homepage_title services_title">
                <p>
                  <span>{{ $t('便捷服務') }}</span>
                </p>
              </div>
              <div class="services_content">
                <div class="arrow_left swiper-button-prev" title="left"></div>
                <div class="arrow_right swiper-button-next" title="right"></div>
                <ul id="servicesScroll">
                  <swiper ref="serviceSwiper" :options="swiperOptions">
                    <template v-for="convenientService_item in homepage.convenientService">
                      <swiper-slide v-if="convenientService_item.title" :key="convenientService_item.id">
                        <div class="icon_link">
                          <a
                            :href="convenientService_item.url"
                            :target="convenientService_item.newWindow ? '_blank' : ''"
                          >
                            <div class="content_item">
                              <div
                                class="content_img"
                                :style="{ 'background-image': 'url(' + convenientService_item.logo + ')' }"
                                :title="convenientService_item.title"
                              ></div>
                            </div>
                            <p class="pt-3">{{ convenientService_item.title }}</p>
                          </a>
                        </div>
                      </swiper-slide>
                    </template>
                  </swiper>
                </ul>
              </div>
            </div>
          </div>
        </u-animate>
      </u-animate-container>
    </template>
    <!--產品資訊-->
    <template v-if="homepage && homepage.product && isMounted">
      <u-animate-container v-if="homepage.product.length > 0 && isMounted">
        <u-animate delay="0s" duration="0.5s" :iteration="1" :offset="0" animateClass="fadeInUp" :begin="false">
          <div class="production" :class="[homepage.product.length > 0 ? '' : 'd-none']">
            <div class="border-0 pb-3 custom-container">
              <div class="homepage_title production_title">
                <p>
                  <span>{{ $t('產品資訊') }}</span>
                </p>
              </div>
              <div class="production_content">
                <div class="arrow_left product-swiper-button-prev" title="left"></div>
                <div class="arrow_right product-swiper-button-next" title="right"></div>
                <div class="content_box">
                  <ul id="productionScroll" class="d-flex">
                    <swiper ref="productSwiper" :options="productSwiperOptions">
                      <swiper-slide v-for="product_item in homepage.product" :key="product_item.id">
                        <b-link
                          :href="product_item.url ? product_item.url : ''"
                          :to="product_item.url ? '' : { name: 'product-detail', params: { id: product_item.id } }"
                          :target="product_item.newWindow ? '_blank' : ''"
                        >
                          <div class="content_item">
                            <div class="item_cover">
                              <b-img-lazy
                                :src="
                                  product_item.cover
                                    ? product_item.cover
                                    : `${require('../assets/images/s_banner0.png')}`
                                "
                                :alt="product_item.title"
                              />
                            </div>
                            <p class="pt-3 content_item_title">{{ product_item.title }}</p>
                            <div class="text" v-html="product_item.content"></div>
                            <b-img-lazy
                              class="add"
                              :src="`${require('../assets/images/icon_more.png')}`"
                              :alt="product_item.title"
                            />
                          </div>
                        </b-link>
                      </swiper-slide>
                    </swiper>
                    <!-- <li class="col-12 col-sm-12 col-md-3" v-for="product_item in product.list.slice(0, 4)" :key="product_item.id">
            <b-link rel="" :href="product_item.url ? product_item.url : ''" :to="product_item.url ? '' : { name: 'product-detail', params: { id: product_item.id } }" :target="product_item.newWindow ? '_blank' : ''">
              <div class="content_item">
                <b-img-lazy :src="product_item.cover ? product_item.cover : `${require('../assets/images/s_banner0.png')}`" />
                <p class="pt-3 content_item_title">{{ product_item.title }}</p>
                <div class="text" v-html="product_item.content"></div>
                <b-img-lazy class="add" :src="`${require('../assets/images/icon_more.png')}`" />
              </div>
            </b-link>
          </li> -->
                  </ul>
                </div>
                <ul>
                  <div class="more">
                    <b-link rel="" :to="{ name: 'product' }" class="more_buttom">
                      <b-icon icon="caret-right-fill" :title="$t('更多')"></b-icon>{{ $t('更多') }}
                    </b-link>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </u-animate>
      </u-animate-container>
    </template>
    <!--金融資訊-->
    <template v-if="homepage && homepage.marketRate">
      <u-animate-container v-if="isMounted">
        <u-animate delay="0s" duration="0.5s" :iteration="1" :offset="0" animateClass="fadeInUp" :begin="false">
          <div class="financial">
            <div class="border-0 pb-3 custom-container" style="background: none">
              <div class="homepage_title financial_title">
                <p>
                  <span>{{ $t('金融資訊') }}</span>
                </p>
              </div>
              <div class="financial_content">
                <div>
                  <div class="market-news">
                    <b-card no-body>
                      <b-tabs
                        class="news-tabs nav-left"
                        card
                        pills
                        vertical
                        lazy
                        justified
                        align="center"
                        v-model="tabsIndex"
                      >
                        <b-tab active>
                          <template v-slot:title>
                            <div class="d-flex align-items-center justify-content-center">
                              <strong class="mx-2">{{ $t('市場報價') }}</strong>
                            </div>
                          </template>
                          <b-tabs
                            content-class="mt-3"
                            class="news-tabs nav-financial"
                            lazy
                            align="center"
                            v-model="financialTabsIndex"
                          >
                            <b-tab>
                              <template v-slot:title>
                                <div class="d-flex align-items-center justify-content-center">
                                  <strong class="mx-2">{{ $t('外幣兑港元匯價') }}</strong>
                                </div>
                              </template>
                              <div class="rate">
                                <table class="container bars">
                                  <thead>
                                    <tr>
                                      <th width="30%">{{ $t('貨幣') }}</th>
                                      <th>{{ $t('買入') }}</th>
                                      <th>{{ $t('賣出') }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="rate in homepage.marketRate.exHkRate" :key="rate.slug">
                                      <td :data-th="$t('貨幣')">
                                        <div class="d-flex align-items-center justify-content-start flag-div">
                                          <b-img-lazy
                                            :src="rate.icon || flagImg(rate.slug)"
                                            sizes="small"
                                            class="col-4 col-md-2 flag-img"
                                            :alt="langName(rate.name)"
                                          ></b-img-lazy>
                                          {{ langName(rate.name) }}
                                        </div>
                                      </td>
                                      <td :data-th="$t('買入')">
                                        {{ rate.buy }}
                                      </td>
                                      <td :data-th="$t('賣出')">
                                        {{ rate.sell }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p class="text-right pt-3 tips">
                                  *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                </p>
                              </div>
                            </b-tab>
                            <b-tab>
                              <template v-slot:title>
                                <div class="d-flex align-items-center justify-content-center">
                                  <strong class="mx-2">{{ $t('外幣兑澳門元匯價') }}</strong>
                                </div>
                              </template>
                              <div class="rate">
                                <table class="container bars">
                                  <thead>
                                    <tr>
                                      <th width="30%">{{ $t('貨幣') }}</th>
                                      <th>{{ $t('買入') }}</th>
                                      <th>{{ $t('賣出') }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="rate in homepage.marketRate.exMopRate" :key="rate.slug">
                                      <td :data-th="$t('貨幣')">
                                        <div class="d-flex align-items-center justify-content-start flag-div">
                                          <b-img-lazy
                                            :src="rate.icon || flagImg(rate.slug)"
                                            sizes="small"
                                            class="col-4 col-md-2 flag-img"
                                            :alt="langName(rate.name)"
                                          ></b-img-lazy>
                                          {{ langName(rate.name) }}
                                        </div>
                                      </td>
                                      <td :data-th="$t('買入')">
                                        {{ rate.buy }}
                                      </td>
                                      <td :data-th="$t('賣出')">
                                        {{ rate.sell }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p class="text-right pt-3 tips">
                                  *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                </p>
                              </div>
                            </b-tab>
                            <b-tab>
                              <template v-slot:title>
                                <div class="d-flex align-items-center justify-content-center">
                                  <strong class="mx-2">{{ $t('外幣兌港元鈔價') }}</strong>
                                </div>
                              </template>
                              <div class="rate">
                                <table class="container bars">
                                  <thead>
                                    <tr>
                                      <th width="30%">{{ $t('貨幣') }}</th>
                                      <th>{{ $t('買入') }}</th>
                                      <th>{{ $t('賣出') }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="rate in homepage.marketRate.noteHkRate" :key="rate.slug">
                                      <td :data-th="$t('貨幣')">
                                        <div class="d-flex align-items-center justify-content-start flag-div">
                                          <b-img-lazy
                                            :src="rate.icon || flagImg(rate.slug)"
                                            sizes="small"
                                            class="col-4 col-md-2 flag-img"
                                            :alt="langName(rate.name)"
                                          ></b-img-lazy>
                                          {{ langName(rate.name) }}
                                        </div>
                                      </td>
                                      <td :data-th="$t('買入')">
                                        {{ rate.buy }}
                                      </td>
                                      <td :data-th="$t('賣出')">
                                        {{ rate.sell }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p class="text-right pt-3 tips">
                                  *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                </p>
                              </div>
                            </b-tab>
                            <b-tab>
                              <template v-slot:title>
                                <div class="d-flex align-items-center justify-content-center">
                                  <strong class="mx-2">{{ $t('外幣兌澳門元鈔價') }}</strong>
                                </div>
                              </template>
                              <div class="rate">
                                <table class="container bars">
                                  <thead>
                                    <tr>
                                      <th width="30%">{{ $t('貨幣') }}</th>
                                      <th>{{ $t('買入') }}</th>
                                      <th>{{ $t('賣出') }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="rate in homepage.marketRate.noteMopRate" :key="rate.slug">
                                      <td :data-th="$t('貨幣')">
                                        <div class="d-flex align-items-center justify-content-start flag-div">
                                          <b-img-lazy
                                            :src="rate.icon || flagImg(rate.slug)"
                                            sizes="small"
                                            class="col-4 col-md-2 flag-img"
                                            :alt="langName(rate.name)"
                                          ></b-img-lazy>
                                          {{ langName(rate.name) }}
                                        </div>
                                      </td>
                                      <td :data-th="$t('買入')">
                                        {{ rate.buy }}
                                      </td>
                                      <td :data-th="$t('賣出')">
                                        {{ rate.sell }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p class="text-right pt-3 tips">
                                  *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                </p>
                              </div>
                            </b-tab>
                            <b-tab>
                              <template v-slot:title>
                                <div class="d-flex align-items-center justify-content-center">
                                  <strong class="mx-2">{{ $t('外幣兑美元匯價') }}</strong>
                                </div>
                              </template>
                              <div class="rate">
                                <table class="container bars">
                                  <thead>
                                    <tr>
                                      <th width="30%">{{ $t('貨幣') }}</th>
                                      <th>{{ $t('買入') }}</th>
                                      <th>{{ $t('賣出') }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="rate in homepage.marketRate.exUsdRate" :key="rate.slug">
                                      <td :data-th="$t('貨幣')">
                                        {{ langName(rate.name) }}
                                      </td>
                                      <td :data-th="$t('買入')">
                                        {{ rate.buy }}
                                      </td>
                                      <td :data-th="$t('賣出')">
                                        {{ rate.sell }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <p class="text-right pt-3 tips">*{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}</p>
                            </b-tab>
                            <b-tab>
                              <template v-slot:title>
                                <div class="d-flex align-items-center justify-content-center">
                                  <strong class="mx-2">{{ $t('存款利率') }}</strong>
                                </div>
                              </template>
                              <div class="rate">
                                <table class="container bars">
                                  <thead>
                                    <tr>
                                      <th>{{ $t('貨幣') }}</th>
                                      <th>{{ $t('儲蓄存款') }}</th>
                                      <th>{{ $t('一星期') }}</th>
                                      <th>{{ $t('二星期') }}</th>
                                      <th>{{ $t('一個月') }}</th>
                                      <th>{{ $t('二個月') }}</th>
                                      <th>{{ $t('三個月') }}</th>
                                      <th>{{ $t('六個月') }}</th>
                                      <th>{{ $t('十二個月') }}</th>
                                      <th>{{ $t('十五個月') }}</th>
                                      <th>{{ $t('十八個月') }}</th>
                                      <th>{{ $t('豐收存款') }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="rate in homepage.marketRate.saveRate" :key="rate.slug">
                                      <td :data-th="$t('貨幣')">
                                        {{ langName(rate.name) }}
                                      </td>
                                      <td :data-th="$t('儲蓄存款')">
                                        {{ rate.save_rage }}
                                      </td>
                                      <td :data-th="$t('一星期')">
                                        {{ rate['1-week'] }}
                                      </td>
                                      <td :data-th="$t('二星期')">
                                        {{ rate['2-week'] }}
                                      </td>
                                      <td :data-th="$t('一個月')">
                                        {{ rate['1-month'] }}
                                      </td>
                                      <td :data-th="$t('二個月')">
                                        {{ rate['2-month'] }}
                                      </td>
                                      <td :data-th="$t('三個月')">
                                        {{ rate['3-month'] }}
                                      </td>
                                      <td :data-th="$t('六個月')">
                                        {{ rate['6-month'] }}
                                      </td>
                                      <td :data-th="$t('十二個月')">
                                        {{ rate['12-month'] }}
                                      </td>
                                      <td :data-th="$t('十五個月')">
                                        {{ rate['15-month'] }}
                                      </td>
                                      <td :data-th="$t('十八個月')">
                                        {{ rate['18-month'] }}
                                      </td>
                                      <td :data-th="$t('豐收存款')">
                                        {{ rate.premium_deposit }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p class="text-right pt-3 tips">
                                  *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                </p>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </b-tab>
                        <!--<b-tab>
                      <template v-slot:title>
                        <div class="d-flex align-items-center justify-content-center">
                          <strong class="mx-2">{{ $t('股票指數') }}</strong>
                        </div>
                      </template>
                      <b-tabs
                        content-class="mt-3"
                        class="news-tabs nav-stock"
                        lazy
                        align="center"
                        v-model="stockTabsIndex"
                      >
                        <b-tab>
                          <template v-slot:title>
                            <div class="d-flex align-items-center justify-content-center">
                              <strong class="mx-2">{{ $t('恆生指數') }}</strong>
                            </div>
                          </template>
                          <div class="chart">
                            <b-img-lazy
                              src="//content.etnet.com.hk/content/taifung/common/chart_oneminute.php?code=HSI"
                              :alt="$t('恆生指數')"
                            />
                          </div>
                          <div class="mt-3 text-right">{{ $t('各大指數延遲最少15分鐘') }}</div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <div class="d-flex align-items-center justify-content-center">
                              <strong class="mx-2">{{ $t('上證綜指') }}</strong>
                            </div>
                          </template>
                          <div class="chart">
                            <b-img-lazy
                              src="//content.etnet.com.hk/content/taifung/common/chart_oneminute.php?type=ashare&code=CSI.000001"
                              :alt="$t('上證綜指')"
                            />
                          </div>
                          <div class="mt-3 text-right">{{ $t('各大指數延遲最少15分鐘') }}</div>
                        </b-tab>
                      </b-tabs>
                    </b-tab>-->
                        <!-- <b-tab>
            <template v-slot:title>
              <div class="d-flex align-items-center justify-content-center">
                <strong class="mx-2">股票資訊</strong>
              </div>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <div class="d-flex align-items-center justify-content-center">
                <strong class="mx-2">基金資訊</strong>
              </div>
            </template>
          </b-tab> -->
                      </b-tabs>
                    </b-card>
                  </div>
                  <div class="market-news-mb">
                    <div role="tablist">
                      <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" role="tab">
                          <b-button block href="#" v-b-toggle.accordion-1 variant="light">{{
                            $t('市場報價')
                          }}</b-button>
                        </b-card-header>
                        <b-collapse visible id="accordion-1" accordion="my-accordion" role="tabpanel">
                          <b-card-body>
                            <b-tabs
                              pills
                              content-class="mt-3"
                              class="news-tabs nav-financial"
                              lazy
                              align="left"
                              v-model="financialTabsIndex"
                            >
                              <b-tab>
                                <template v-slot:title>
                                  <div class="d-flex col-12 align-items-center justify-content-center">
                                    <strong class="mx-2">{{ $t('外幣兑港元匯價') }}</strong>
                                  </div>
                                </template>
                                <div class="rate">
                                  <table class="container bars">
                                    <thead>
                                      <tr>
                                        <th width="30%">{{ $t('貨幣') }}</th>
                                        <th>{{ $t('買入') }}</th>
                                        <th>{{ $t('賣出') }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="rate in homepage.marketRate.exHkRate" :key="rate.slug">
                                        <td :data-th="$t('貨幣')">
                                          <div class="d-flex align-items-center justify-content-start flag-div">
                                            <b-img-lazy
                                              :src="rate.icon || flagImg(rate.slug)"
                                              sizes="small"
                                              class="col-4 col-md-2 flag-img"
                                              :alt="langName(rate.name)"
                                            ></b-img-lazy>
                                            {{ langName(rate.name) }}
                                          </div>
                                        </td>
                                        <td :data-th="$t('買入')">
                                          {{ rate.buy }}
                                        </td>
                                        <td :data-th="$t('賣出')">
                                          {{ rate.sell }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p class="text-right pt-3 tips">
                                    *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                  </p>
                                </div>
                              </b-tab>
                              <b-tab>
                                <template v-slot:title>
                                  <div class="d-flex col-12 align-items-center justify-content-center">
                                    <strong class="mx-2">{{ $t('外幣兑澳門元匯價') }}</strong>
                                  </div>
                                </template>
                                <div class="rate">
                                  <table class="container bars">
                                    <thead>
                                      <tr>
                                        <th width="30%">{{ $t('貨幣') }}</th>
                                        <th>{{ $t('買入') }}</th>
                                        <th>{{ $t('賣出') }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="rate in homepage.marketRate.exMopRate" :key="rate.slug">
                                        <td :data-th="$t('貨幣')">
                                          <div class="d-flex align-items-center justify-content-start flag-div">
                                            <b-img-lazy
                                              :src="rate.icon || flagImg(rate.slug)"
                                              sizes="small"
                                              class="col-4 col-md-2 flag-img flag-div"
                                              :alt="langName(rate.name)"
                                            ></b-img-lazy>
                                            {{ langName(rate.name) }}
                                          </div>
                                        </td>
                                        <td :data-th="$t('買入')">
                                          {{ rate.buy }}
                                        </td>
                                        <td :data-th="$t('賣出')">
                                          {{ rate.sell }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p class="text-right pt-3 tips">
                                    *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                  </p>
                                </div>
                              </b-tab>
                              <b-tab>
                                <template v-slot:title>
                                  <div class="d-flex col-12 align-items-center justify-content-center">
                                    <strong class="mx-2">{{ $t('外幣兌港元鈔價') }}</strong>
                                  </div>
                                </template>
                                <div class="rate">
                                  <table class="container bars">
                                    <thead>
                                      <tr>
                                        <th width="30%">{{ $t('貨幣') }}</th>
                                        <th>{{ $t('買入') }}</th>
                                        <th>{{ $t('賣出') }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="rate in homepage.marketRate.noteHkRate" :key="rate.slug">
                                        <td :data-th="$t('貨幣')">
                                          <div class="d-flex align-items-center justify-content-start flag-div">
                                            <b-img-lazy
                                              :src="rate.icon || flagImg(rate.slug)"
                                              sizes="small"
                                              class="col-4 col-md-2 flag-img flag-div"
                                              :alt="langName(rate.name)"
                                            ></b-img-lazy>
                                            {{ langName(rate.name) }}
                                          </div>
                                        </td>
                                        <td :data-th="$t('買入')">
                                          {{ rate.buy }}
                                        </td>
                                        <td :data-th="$t('賣出')">
                                          {{ rate.sell }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p class="text-right pt-3 tips">
                                    *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                  </p>
                                </div>
                              </b-tab>
                              <b-tab>
                                <template v-slot:title>
                                  <div class="d-flex col-12 align-items-center justify-content-center">
                                    <strong class="mx-2">{{ $t('外幣兌澳門元鈔價') }}</strong>
                                  </div>
                                </template>
                                <div class="rate">
                                  <table class="container bars">
                                    <thead>
                                      <tr>
                                        <th width="30%">{{ $t('貨幣') }}</th>
                                        <th>{{ $t('買入') }}</th>
                                        <th>{{ $t('賣出') }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="rate in homepage.marketRate.noteMopRate" :key="rate.slug">
                                        <td :data-th="$t('貨幣')">
                                          <div class="d-flex align-items-center justify-content-start flag-div">
                                            <b-img-lazy
                                              :src="rate.icon || flagImg(rate.slug)"
                                              sizes="small"
                                              class="col-4 col-md-2 flag-img flag-div"
                                              :alt="langName(rate.name)"
                                            ></b-img-lazy>
                                            {{ langName(rate.name) }}
                                          </div>
                                        </td>
                                        <td :data-th="$t('買入')">
                                          {{ rate.buy }}
                                        </td>
                                        <td :data-th="$t('賣出')">
                                          {{ rate.sell }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p class="text-right pt-3 tips">
                                    *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                  </p>
                                </div>
                              </b-tab>
                              <b-tab>
                                <template v-slot:title>
                                  <div class="d-flex col-12 align-items-center justify-content-center">
                                    <strong class="mx-2">{{ $t('外幣兑美元匯價') }}</strong>
                                  </div>
                                </template>
                                <div class="rate">
                                  <table class="container bars">
                                    <thead>
                                      <tr>
                                        <th width="30%">{{ $t('貨幣') }}</th>
                                        <th>{{ $t('買入') }}</th>
                                        <th>{{ $t('賣出') }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="rate in homepage.marketRate.exUsdRate" :key="rate.slug">
                                        <td :data-th="$t('貨幣')">
                                          {{ langName(rate.name) }}
                                        </td>
                                        <td :data-th="$t('買入')">
                                          {{ rate.buy }}
                                        </td>
                                        <td :data-th="$t('賣出')">
                                          {{ rate.sell }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <p class="text-right pt-3 tips">
                                  *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                </p>
                              </b-tab>
                              <b-tab>
                                <template v-slot:title>
                                  <div class="d-flex col-12 align-items-center justify-content-center">
                                    <strong class="mx-2">{{ $t('存款利率') }}</strong>
                                  </div>
                                </template>
                                <div class="rate">
                                  <table class="container bars">
                                    <thead>
                                      <tr>
                                        <th>{{ $t('貨幣') }}</th>
                                        <th>{{ $t('儲蓄存款') }}</th>
                                        <th>{{ $t('一星期') }}</th>
                                        <th>{{ $t('二星期') }}</th>
                                        <th>{{ $t('一個月') }}</th>
                                        <th>{{ $t('二個月') }}</th>
                                        <th>{{ $t('三個月') }}</th>
                                        <th>{{ $t('六個月') }}</th>
                                        <th>{{ $t('十二個月') }}</th>
                                        <th>{{ $t('十五個月') }}</th>
                                        <th>{{ $t('十八個月') }}</th>
                                        <th>{{ $t('豐收存款') }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="rate in homepage.marketRate.saveRate" :key="rate.slug">
                                        <td :data-th="$t('貨幣')">
                                          {{ langName(rate.name) }}
                                        </td>
                                        <td :data-th="$t('儲蓄存款')">
                                          {{ rate.save_rage }}
                                        </td>
                                        <td :data-th="$t('一星期')">
                                          {{ rate['1-week'] }}
                                        </td>
                                        <td :data-th="$t('二星期')">
                                          {{ rate['2-week'] }}
                                        </td>
                                        <td :data-th="$t('一個月')">
                                          {{ rate['1-month'] }}
                                        </td>
                                        <td :data-th="$t('二個月')">
                                          {{ rate['2-month'] }}
                                        </td>
                                        <td :data-th="$t('三個月')">
                                          {{ rate['3-month'] }}
                                        </td>
                                        <td :data-th="$t('六個月')">
                                          {{ rate['6-month'] }}
                                        </td>
                                        <td :data-th="$t('十二個月')">
                                          {{ rate['12-month'] }}
                                        </td>
                                        <td :data-th="$t('十五個月')">
                                          {{ rate['15-month'] }}
                                        </td>
                                        <td :data-th="$t('十八個月')">
                                          {{ rate['18-month'] }}
                                        </td>
                                        <td :data-th="$t('豐收存款')">
                                          {{ rate.premium_deposit }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p class="text-right pt-3 tips">
                                    *{{ $t('以上資料僅供參考（交易時以本行報價為准）') }}
                                  </p>
                                </div>
                              </b-tab>
                            </b-tabs>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                      <!-- <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" role="tab">
                      <b-button block href="#" v-b-toggle.accordion-2 variant="light">{{
                        $t('股票指數')
                      }}</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                      <b-card-body>
                        <b-tabs
                          content-class="mt-3"
                          class="news-tabs nav-stock"
                          lazy
                          align="center"
                          v-model="stockTabsIndex"
                        >
                          <b-tab>
                            <template v-slot:title>
                              <div class="d-flex align-items-center justify-content-center">
                                <strong class="mx-2">{{ $t('恆生指數') }}</strong>
                              </div>
                            </template>
                            <div class="chart">
                              <b-img-lazy
                                src="//content.etnet.com.hk/content/taifung/common/chart_oneminute.php?code=HSI"
                                :alt="$t('恆生指數')"
                              />
                            </div>
                            <div class="mt-3 text-right">{{ $t('各大指數延遲最少15分鐘') }}</div>
                          </b-tab>
                          <b-tab>
                            <template v-slot:title>
                              <div class="d-flex align-items-right justify-content-center">
                                <strong class="mx-2">{{ $t('上證綜指') }}</strong>
                              </div>
                            </template>
                            <div class="chart">
                              <b-img-lazy
                                src="//content.etnet.com.hk/content/taifung/common/chart_oneminute.php?type=ashare&code=CSI.000001"
                                :alt="$t('上證綜指')"
                              />
                            </div>
                            <div class="mt-3 text-right">{{ $t('各大指數延遲最少15分鐘') }}</div>
                          </b-tab>
                        </b-tabs>
                      </b-card-body>
                    </b-collapse>
                  </b-card> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </u-animate>
      </u-animate-container>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import { BIcon, BIconCaretRightFill, BIconChevronRight } from 'bootstrap-vue'
import { lanToISO639 } from '@/utils'
import { ISO639ToLan } from '@/utils'
import { isCSR } from '@/utils'
let grayscale = null
if (isCSR()) {
  require('@/plugins/wow-vue.js')
  // grayscale = require('@/assets/grayscale.js')
}
export default {
  // asyncData: fetchInitialData,
  components: {
    BIcon,
    BIconCaretRightFill,
    BIconChevronRight
  },
  data() {
    return {
      isMounted: false,
      ssrShow: false,
      swiperInit: false,
      swiperOptions: {
        slidesPerView: 6,
        // slidesPerColumn : 1,
        slidesPerColumnFill: 'row',
        spaceBetween: 30,
        // autoHeight: true,
        updateOnWindowResize: true,
        observer: true,
        observeParents: true,
        setWrapperSize: true,
        autoplay: {
          disableOnInteraction: false
        },
        loop: true,
        grabCursor: true,
        // pagination: {
        //   el: '.swiper-pagination'
        // },
        breakpoints: {
          768: {
            loop: false,
            slidesPerView: 2,
            slidesPerColumn: 2,
            slidesPerColumnFill: 'column',
            spaceBetween: 10
          },
          1280: {
            slidesPerView: 4
          }
        },
        on: {
          init: () => {
            this.swiperInit = true
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      productSwiperOptions: {
        slidesPerView: 4,
        slidesPerColumnFill: 'row',
        spaceBetween: 0,
        updateOnWindowResize: true,
        observer: true,
        observeParents: true,
        setWrapperSize: true,
        grabCursor: true,
        breakpoints: {
          414: {
            slidesPerView: 1
          },
          900: {
            slidesPerView: 2
          },
          1200: {
            slidesPerView: 3
          }
        },
        navigation: {
          nextEl: '.product-swiper-button-next',
          prevEl: '.product-swiper-button-prev'
        }
      },
      bannerSwiperOptions: {
        slidesPerView: 1,
        slidesPerColumnFill: 'row',
        spaceBetween: 0,
        updateOnWindowResize: true,
        observer: true,
        observeParents: true,
        setWrapperSize: true,
        grabCursor: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 6000
        },
        navigation: {
          nextEl: '.banner-swiper-button-next',
          prevEl: '.banner-swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      tabsIndex: 1,
      financialTabsIndex: 0,
      stockTabsIndex: 0
      // financialContent: ''
    }
  },
  serverPrefetch() {
    return new Promise(resolve => {
      this.fetchindexInitData().finally(() => resolve(this.$store.commit('common/SET_SERVER_FETCHED', true)))
    })
  },
  seoInfo() {
    try {
      return {
        title: this.homepage.page.metaTitle,
        description: this.page.homepage.metaDescription || this.homepage.page.metaTitle,
        keywords: this.homepage.page.metaKeywords || this.homepage.page.metaTitle,
        metas: this.homepage.page.metaExtras
      }
    } catch (err) {}
  },
  mounted() {
    if (!this.serverFetched) {
      this.fetchindexInitData()
    }
    this.isMounted = true
    this.handleInit()
  },
  computed: {
    ...mapState('page', [
      'homepage'
      // 'banners',
      // 'bannersMobile',
      // 'homepageNavigation',
      // 'news',
      // 'promotion',
      // 'product',
      // 'convenientService',
      // 'marketRate'
    ]),
    ...mapState('common', ['serverFetched', 'page', 'home_activity', 'grayStyle']),

    title() {
      return this.$store.getters.homepageContent.title
    },
    // content() {
    //   return this.$store.getters.homepageContent.content
    // },
    lang() {
      return ISO639ToLan(this.$getLocale())
    }
  },
  methods: {
    flagImg(src) {
      try {
        return `${require('../assets/images/flag/' + src + '.png')}`
      } catch {
        return `${require('../assets/images/flag/TFG.png')}`
      }
    },
    fetchindexInitData() {
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      // return new Promise(resolve => {
      //   updateSeoInfo.call(this)
      //   resolve()
      // })

      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('page/FETCH_HOMEPAGE', {
            cookies
          }),
          this.$store.dispatch('common/FETCH_GRAYSTYLE', {})

          // this.$store.dispatch('page/FETCH_BANNERS', {
          //   cookies,
          //   slug: 'homepage-slider'
          // }),
          // this.$store.dispatch('page/FETCH_BANNERSMOBILE', {
          //   cookies,
          //   slug: 'homepage-slider-mobile'
          // }),
          // this.$store.dispatch('page/FETCH_HOMEPAGE_NAVIGATION', {
          //   cookies,
          //   slug: 'homepage-navigation'
          // }),
          // this.$store.dispatch('page/FETCH_NEWS', {
          //   cookies,
          //   slug: 'latest-news',
          //   limit: '6'
          // }),
          // this.$store.dispatch('common/FETCH_PAGE', {
          //   path: 'home'
          // }),
          // this.$store.dispatch('page/FETCH_PROMOTION', {
          //   cookies,
          //   slug: 'promotion',
          //   limit: 8
          // }),
          // this.$store.dispatch('page/FETCH_CONVENIENT_SERVICE', {
          //   cookies,
          //   type: 'convenient-service'
          // }),
          // this.$store.dispatch('page/FETCH_PRODUCT', {
          //   cookies,
          //   slug: 'product',
          //   limit: 4
          // }),
          // this.$store.dispatch('page/FETCH_MARKET_RATE', {
          //   cookies
          // })
        ])
          .then(() => {})
          .finally(() => {
            resolve(updateSeoInfo.call(this))
            this.handleInit()
          })
          .catch(reject)
      })
    },
    async handleInit() {
      // this.isMounted = true
      // if ((await this.grayStyle) && (await this.homepage)) {
      //   if (this.grayStyle == '1' && this.isMounted) {
      //     this.$nextTick(() => {
      //       // setTimeout(() => {
      //       // grayscale(document.body)
      //       var navStr = navigator.userAgent.toLowerCase()
      //       if (navStr.indexOf('msie 10.0') !== -1 || navStr.indexOf('rv:11.0') !== -1) {
      //         grayscale(document.body)
      //       }
      //       // }, 1000)
      //     })
      //   }
      // }
      // this.financialContent = this.marketRate
    },
    langName(name) {
      return name[lanToISO639(this.$route.params.lang)]
    },
    // prev() {
    //   this.$refs.myCarousel.prev()
    // },
    // next() {
    //   this.$refs.myCarousel.next()
    // },
    GoTop() {
      var gotoTop = function() {
        var currentPosition = document.documentElement.scrollTop || document.body.scrollTop
        currentPosition -= 10
        if (currentPosition > 0) {
          window.scrollTo(0, currentPosition)
        } else {
          window.scrollTo(0, 0)
          clearInterval(timer)
          timer = null
        }
      }
      var timer = setInterval(gotoTop, 1)
    }
  }
}
</script>
